import LoginForm from './components/LoginForm'

export default function App() {
    return (
        <div className="h-screen flex flex-col gap-8 items-center justify-center">
            <div className="text-center">
                <h2 className="text-lg italic">Welcome to your favorite tracking tool!</h2>
                <h1 className="text-xl text-cyan-700">Null-nlyFans.de</h1>
            </div>
            <div className="w-96">
                <LoginForm />
            </div>
            <div className="h-1/5"></div>
        </div>
    )
}