import { Button, Checkbox, Label, TextInput } from 'flowbite-react';

export default function LoginForm() {
    return (
        <form className="flex flex-col gap-4" onSubmit={e => {
            e.preventDefault()
            alert("not implemented")
        }}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email1" value="Username (your OnlyFans handle)" />
                </div>
                <TextInput id="email1" type="text" placeholder="sweetypi69" required />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password1" value="Password" />
                </div>
                <TextInput id="password1" type="password" required />
            </div>
            <div className="flex items-center gap-2">
                <Checkbox id="remember" />
                <Label htmlFor="remember">Keep me logged in</Label>
            </div>
            <Button type="submit">Login</Button>
        </form>
    )
}